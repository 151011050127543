import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../../../components'
import { Seo } from '../../../components/base'
import { LayoutBackground } from '../../../components/shared'
import { ApplicationForm } from '../../../components/forms'
const ApplicationPage = ({ data, pageContext }) => {
  const { title, desc, applicationForm } = data.applicationPage.nodes[0]
  const [formSubmitted, setFormSubmitted] = useState()
  const markets = [...data.bmvMarkets.nodes, ...data.dennsMarkets.nodes]

  const layoutBackgroundHeaderComponent = (
    <div className="mx-auto max-w-6xl mb-14 md:mb-24">
      <h1 className="mb-5 md:mb-11 text-center">{title}</h1>
      {!formSubmitted && <p className="text-left">{desc}</p>}
    </div>
  )

  const languages = { pageContext }

  return (
    <Layout standardPaddingTopFromNavbarToMain translations={languages}>
      <Seo title={title} description={desc} />
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <section className="md:px-10 lg:px-0 max-w-6xl mx-auto">
          <ApplicationForm
            formData={{
              ...applicationForm?.personal,
              ...applicationForm?.additional,
              ...applicationForm?.dataConsent,
              ...applicationForm?.uploads
            }}
            setFormSubmitted={setFormSubmitted}
            markets={markets}
            pageContent={data.applicationPage}
            sendMessage={applicationForm?.submitMessage}
            errorMessage={applicationForm?.errorMessage}
            noSearchResultText={applicationForm?.noSearchResultText}
          />
        </section>
      </LayoutBackground>
    </Layout>
  )
}

export default ApplicationPage

export const pageQuery = graphql`
  query ($language: String!) {
    applicationPage: allSanityApplicationPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        i18n_lang
        title
        desc
        overviewHeadline
        overviewSubline
        personalInformationHeadline
        additionalInformationHeadline
        dataProtectionHeadline
        attachmentHeadline
        requiredFieldsNote
        applicationForm {
          submitMessage
          errorMessage
          noSearchResultText
          personal {
            city {
              placeholder
              hint
              required
              type
            }
            emailAddress {
              placeholder
              hint
              required
              type
            }
            firstName {
              placeholder
              hint
              required
              type
            }
            lastName {
              placeholder
              hint
              required
              type
            }
            phoneNumber {
              placeholder
              hint
              required
              type
            }
            ageConsent {
              _rawLabel
              required
            }
            salutation {
              options
              placeholder
              required
            }
            street {
              placeholder
              hint
              required
              type
            }
            zipcode {
              placeholder
              hint
              required
              type
            }
            country {
              placeholder
              hint
              required
              type
            }
          }
          additional {
            referenceFrom {
              options
              placeholder
              required
            }
            preferedLocation {
              options
              placeholder
              required
            }
            salaryExpectations {
              placeholder
              hint
              required
              type
            }
            startDate {
              placeholder
              hint
              required
              type
            }
          }
          dataConsent {
            dataConsent {
              _rawLabel
              required
            }
          }
          uploads {
            uploadFields {
              hint
              required
              title
              name
              fileTypes
            }
          }
        }
      }
    }
    dennsMarkets: allSanityMarket(
      filter: {
        marketType: { eq: 1 }
        status: { in: ["5", "9"] }
        address: { lat: { regex: "/[1-9]/" }, lon: { regex: "/[1-9]/" } }
      }
      sort: { fields: address___zip, order: ASC }
    ) {
      nodes {
        name
        marketId
        id
        _id
        address {
          city
          street
          zip
        }
      }
    }
    bmvMarkets: allSanityMarket(
      filter: {
        contact: { applicationEmail: { regex: "/[a-z0-9]/i" } }
        marketType: { eq: 2 }
      }
      sort: { fields: address___zip, order: ASC }
    ) {
      nodes {
        name
        marketId
        id
        _id
        address {
          city
          street
          zip
        }
        contact {
          applicationEmail
        }
      }
    }
  }
`
